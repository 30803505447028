import React from "react"
import styled from "@emotion/styled"
import { graphql, navigate } from "gatsby"

import { borders, colors, shadows } from "../styles"

import Seo from "../components/seo"
import Layout from "../components/layout"

import PricingCard from "../components/pricing-card"
import Testimonial from "../components/testimonial"

import ImageLoader from "../components/image-loader"

const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  margin: 0;

  @media screen and (min-width: 1280px) {
    flex-direction: row;
    align-items: center;
    margin: 0 1rem;
  }

  @media screen and (min-width: 1439px) {
    margin: 0 5rem;
  }
`

const IntroBlurb = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
`

const PricingSection = styled(Section)`
  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;

    > div:last-of-type {
      display: none;
    }
  }

  @media screen and (min-width: 1280px) {
    > div:last-of-type {
      display: block;
    }
  }
`

const StudioImage = styled.div`
  order: 1;
  flex: 1 1 auto;
  box-shadow: ${shadows.standard};
  border: ${borders.standard};
  border-radius: 0;

  @media screen and (min-width: 768px) {
    border-radius: 0.5rem;
    overflow: hidden;
  }

  @media screen and (min-width: 1280px) {
    order: 0;
  }
`

const StudioBlurb = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;

  @media screen and (min-width: 1280px) {
    width: 30%;
  }
`

const TestimonialSection = styled(Section)`
  border: ${borders.standard};
  border-radius: none;
  box-shadow: ${shadows.standard};
  background-color: ${colors.primary};

  padding: 1rem 2rem;
  gap: 0;

  @media screen and (min-width: 768px) {
    margin: 0 -2rem;
    padding: 1rem 4rem;
  }

  @media screen and (min-width: 1280px) {
    flex-direction: column;
    margin: 0 -4rem;
    padding: 2rem 6rem;
  }
`

const Testimonials = styled.div`
  display: flex;
  margin: 0 -2rem;

  ${Testimonial} {
    display: none;
  }

  ${Testimonial}:first-of-type {
    display: block;
  }

  @media screen and (min-width: 1280px) {
    ${Testimonial} {
      display: block;
    }
  }
`

export default class IndexPage extends React.Component {
  render() {
    const { data } = this.props
    const testimonials = data.testimonials.edges.map((edge) => {
      return {
        author: edge.node.frontmatter.author,
        body: edge.node.rawMarkdownBody,
      }
    })

    const voice = data.pricing.edges.find(
      ({ node }) => node.frontmatter.lessontype === "voice"
    )?.node?.frontmatter
    const guitar = data.pricing.edges.find(
      ({ node }) => node.frontmatter.lessontype === "guitar"
    )?.node?.frontmatter
    const piano = data.pricing.edges.find(
      ({ node }) => node.frontmatter.lessontype === "piano"
    )?.node?.frontmatter

    return (
      <Layout showHero>
        <Seo title="Sing Smart Studios | Townsville Singing School" />
        <Section>
          <IntroBlurb>
            <h1>
              Learn with
              <br />
              Sing Smart Studios
            </h1>
            <p>
              Whether just starting out, or singing professionally for years,
              you'll be able to bring out the very best in your voice at Sing
              Smart Studios.
            </p>
            <p>
              We'll travel beside you on your vocal journey, building on your
              strengths and helping you overcome any challenges.
            </p>
          </IntroBlurb>
        </Section>

        <PricingSection>
          {voice.enabled && (
            <PricingCard
              title="Singing lessons"
              copy="Enhance your Tone, Range and Technique through our high quality singing lessons and expert guidance."
              imageSrc={voice.image}
              imageAlt="Singing lessons"
              buttonText="Book now"
              onClick={() => navigate("/contact")}
              price={voice.price}
              priceSuffix={voice.pricesuffix}
              duration={voice.duration}
              durationSuffix={voice.durationsuffix}
            />
          )}

          {piano.enabled && (
            <PricingCard
              title="Piano lessons"
              copy="Learn to play, read music and gain the skills for creativity and musical expression."
              imageSrc={piano.image}
              imageAlt="Piano lessons"
              buttonText="Book now"
              onClick={() => navigate("/contact")}
              price={piano.price}
              priceSuffix={piano.pricesuffix}
              duration={piano.duration}
              durationSuffix={piano.durationsuffix}
            />
          )}

          {guitar.enabled && (
            <PricingCard
              title="Guitar lessons"
              copy="Guitar lessons can be booked at our studio through Six Strings Townsville."
              imageSrc={guitar.image}
              imageAlt="Guitar lessons"
              buttonText="Book now"
              onClick={() => navigate("/contact")}
              price={guitar.price}
              priceSuffix={guitar.pricesuffix}
              duration={guitar.duration}
              durationSuffix={guitar.durationsuffix}
            />
          )}
        </PricingSection>

        <Section>
          <StudioImage>
            <ImageLoader alt="Studio Room" src="/assets/studio-1b.jpg" />
          </StudioImage>
          <StudioBlurb>
            <h1>Practise with privacy and comfort</h1>
            <p>
              At Sing Smart Studios you'll work with our teachers to build and
              enhance your own unique sound and style within a safe and
              encouraging environment.
            </p>
            <p>
              Our facilities include four sound-proof studio rooms. Each with
              comfortable furniture, quality recording equipment and cool
              air-conditioning.
            </p>
          </StudioBlurb>
        </Section>

        <TestimonialSection>
          <h1>Hear from our students</h1>
          <Testimonials>
            {testimonials.map((testimonial) => (
              <Testimonial key={testimonial.author}>
                <p>{testimonial.body}</p>
                <span>{testimonial.author}</span>
              </Testimonial>
            ))}
          </Testimonials>
        </TestimonialSection>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    testimonials: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/markdown/testimonials/" }
        wordCount: { words: { lte: 40 } }
      }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            author
          }
          rawMarkdownBody
        }
      }
    }

    pricing: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/markdown/pricing/" } }
    ) {
      edges {
        node {
          frontmatter {
            lessontype
            enabled
            image
            price
            pricesuffix
            duration
            durationsuffix
          }
        }
      }
    }
  }
`
