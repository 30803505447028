import React from "react"
import styled from "@emotion/styled"
import { OutboundLink } from "gatsby-plugin-amplitude-analytics"
import ImageLoader from "./image-loader"

import { FaDollarSign, FaRegClock } from "react-icons/fa"

import { borders, colors, shadows } from "../styles"

const Container = styled.div`
  height: 100%;
  /* amplitude wrapper */
  > a {
    display: flex;
    flex-direction: column;
    background-color: ${colors.primary};
    border-radius: 0;
    overflow: hidden;
    cursor: pointer;
    border: ${borders.standard};
    box-shadow: ${shadows.standard};
    transition: 0.25s ease-in-out;

    @media screen and (min-width: 768px) {
      height: 100%;
      width: 21.25rem;
      border-radius: 0.5rem;
    }

    @media screen and (min-width: 1280px) {
      border-radius: 0.5rem;
    }
  }

  > a:hover {
    transform: scale(1.025);
  }
`

const ImageContainer = styled.div`
  overflow: hidden;
  height: 14rem;
  border-bottom: ${borders.standard};
  flex-shrink: 0;

  .gatsby-image-wrapper {
    aspect-ratio: 4 / 3;
  }
`

const Content = styled.div`
  padding: 0 2rem;
  padding-top: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
`

const Title = styled.h2`
  text-align: center;
`

const Detail = styled.div`
  display: flex;
  align-items: center;

  & span {
    font-weight: 600;
    margin-left: 1rem;
  }

  &:first-of-type {
    margin-top: auto;
  }
`

const Button = styled.button`
  line-height: 1.5rem;
  font-size: 1.25rem;
  font-weight: 600;
  color: white;
  padding: 0.75rem 1.5rem;
  margin-top: auto;
  margin-bottom: 1.25rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  background-color: transparent;
  border: solid 1px white;
  border-radius: 0.5rem;
  transition: 0.25s ease-in-out;
  cursor: pointer;

  &:hover {
    color: ${colors.darkGrey};
    background-color: white;
  }
`

const PricingCard = ({
  title,
  copy,
  imageSrc,
  imageAlt,
  buttonText,
  href,
  ariaLabel,
  onClick,
  price,
  priceSuffix,
  duration,
  durationSuffix,
}) => {
  return (
    <Container onClick={onClick}>
      <OutboundLink
        href={href}
        aria-label={ariaLabel}
        target="_blank"
        rel="noopener"
      >
        <ImageContainer>
          <ImageLoader alt={imageAlt} src={imageSrc} />
        </ImageContainer>
        <Content>
          <Title>{title}</Title>
          <p>{copy}</p>
          <Detail>
            <FaDollarSign />
            <span>
              {price} {priceSuffix}
            </span>
          </Detail>
          <Detail>
            <FaRegClock />
            <span>
              {duration} {durationSuffix}
            </span>
          </Detail>
        </Content>
        <Button>{buttonText}</Button>
      </OutboundLink>
    </Container>
  )
}

export default PricingCard
