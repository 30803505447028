import styled from "@emotion/styled"

const Testimonial = styled.blockquote`
  position: relative;
  max-width: 20rem;
  padding: 0.5rem;

  @media only screen and (max-width: 1439px) {
    max-width: 80%;
  }

  @media only screen and (max-width: 767px) {
    max-width: 100%;
  }

  > p {
    font-weight: 100;
    font-size: 1.5rem;
    line-height: 1.4;

    & ::before,
    ::after {
      position: absolute;
      color: white;
      font-size: 10rem;
      font-weight: 400;
      line-height: 1;
      height: 5rem;
      opacity: 0.15;
    }

    & ::before {
      content: "“";
      transform: translate(-2rem, -2rem);
    }

    & ::after {
      content: "”";
      transform: translate(-1rem, -1rem);
    }
  }

  > span {
    position: absolute;
    right: 2rem;
    font-weight: 100;
    font-size: 1rem;
    opacity: 0.25;

    & ::before {
      content: "— ";
    }
  }
`

export default Testimonial
